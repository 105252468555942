import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'

import * as Configuration from './configuration'

export function PaymentProviderImages() {
  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviderImageUrls(state.configuration)
  )

  const PaymentProvidersWrapper = styled(Common.Box)`
    gap: 16px;

    ${t.mediaQuery.sm`
    gap: 44px;
  `}
  `

  return (
    <PaymentProvidersWrapper
      alignItems="center"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
    >
      {R.map(
        item => (
          <Common.Box
            as="img"
            alt={item.name}
            height="100%"
            maxHeight={['25px', '35px']}
            src={item.image.url}
            key={item.name}
          />
        ),
        paymentProviders
      )}
    </PaymentProvidersWrapper>
  )
}
