import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'

import * as Configuration from '../configuration'
import * as Icons from '../icons'
import * as ClientConfig from '../client-configuration'
import { HtmlContent } from '../html-content'
import { PaymentProviderImages } from '../payment-providers'
import { useSafeUpdateQuery } from '../use-safe-update-query'

import { LandingPageLayout } from './landing-page-default-layout'

const RegisterButton = styled.button`
  color: #ffffff;
  width: 350px;
  padding: 16px 24px;
  border-radius: 6px;
  border: none;
  border-top: 1px solid #dbf2c6;
  background: linear-gradient(0deg, #00b67a 0%, #00b67a 100%),
    linear-gradient(128deg, #f12d83 7.19%, #5217a3 97.03%);
  box-shadow: 0px 3px 12px 0px rgba(0, 182, 122, 0.4);
  &:hover,
  &:active {
    background: linear-gradient(0deg, #00c685 0%, #00c685 100%),
      linear-gradient(128deg, #f12d83 7.19%, #5217a3 97.03%);
    cursor: pointer;
  }
`

const GoldText = styled.span`
  background-clip: text;
  background: linear-gradient(180deg, #fbf792 20%, #fcff52 40%, #d5a539 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
const Ribbon = styled.div`
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  line-height: 3;
  font-size: 10px;
  font-weight: 600;
  padding-inline: 0.9lh;
  padding-bottom: var(--f);
  border-image: conic-gradient(#0008 0 0) 51% / var(--f);
  clip-path: polygon(
    100% calc(100% - var(--f)),
    100% 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    var(--f) calc(100% - var(--f)),
    0 100%,
    0 calc(100% - var(--f)),
    999px calc(100% - var(--f) - 999px),
    calc(100% - 999px) calc(100% - var(--f) - 999px)
  );
  transform: translate(calc((cos(45deg) - 1) * 100%), -100%) rotate(-45deg);
  transform-origin: 100% 100%;
  background: linear-gradient(180deg, #42aadf, #1264b6);
  ${t.mediaQuery.sm`
  font-size: 12px;
`}
`

function LandingPageBottomPart(props) {
  const i18n = I18n.useI18n()
  const history = ReactRouter.useHistory()

  return (
    <>
      <Common.Box mt={['24px', 'unset']} color={['#101010', 'white']}>
        <RegisterButton onClick={() => history.push(`?${props.registerQuery}`)}>
          <Common.Box
            fontSize={['16px', '18px']}
            fontWeight="700"
            lineHeight={['110%', '130%']}
          >
            {i18n.translate('landing-page.register')}
          </Common.Box>
        </RegisterButton>
        <Common.Box
          display="flex"
          flexDirection={['row', 'column']}
          fontSize={['12px', '16px']}
          pt="24px"
        >
          <Common.Flex>
            <Icons.Lock_2 />
            <Common.Box pl="6px">
              {i18n.translate('landing-page.ups-secure')}
            </Common.Box>
          </Common.Flex>
          <Common.Box display="flex" py={['0px', '16px']} px={['16px', '0px']}>
            <Icons.SimpleLightning />
            <Common.Box pl="6px">
              {i18n.translate('landing-page.ups-instant-wd')}
            </Common.Box>
          </Common.Box>
          <Common.Flex>
            <Icons.SmilingFace />
            <Common.Box pl="6px">
              {i18n.translate('landing-page.ups-support')}
            </Common.Box>
          </Common.Flex>
        </Common.Box>
      </Common.Box>
      <Common.Box
        display="flex"
        flexDirection="row"
        height={['36px', '60px']}
        color={['#101010', 'white']}
        mt={['32px', '0px']}
      >
        <Common.Box
          as="img"
          width="auto"
          src={
            props.mobileClient
              ? '/images/trustpilot-dark-text.svg'
              : '/images/trustpilot.svg'
          }
        />
        <Common.Box
          pl={['16px', '24px']}
          pt={['16px', '26px']}
          fontWeight={['400', '500']}
          fontSize={['10px', '14px']}
          lineHeight="120%"
        >
          {i18n.translate(`landing-page.trustpilot-rating`)}
        </Common.Box>
      </Common.Box>
    </>
  )
}

LandingPageBottomPart.propTypes = {
  mobileClient: PropTypes.bool,
  registerQuery: PropTypes.object.isRequired,
}

export function LandingPage(props) {
  const i18n = I18n.useI18n()
  const registerQuery = useSafeUpdateQuery({ register: 'me' })
  const clientType = ClientConfig.useClientType()
  const mobileClient = clientType === 'mobile-browser'
  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviderImageUrls(state.configuration)
  )

  return (
    <LandingPageLayout>
      <Common.Box
        display="flex"
        flexDirection="column"
        height={[null, '100vh']}
        color="#ffffff"
      >
        <Common.Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          flexGrow="1"
          backgroundImage={[
            `url(${i18n.translate('landing-page.background.mobile')})`,
            `url(${i18n.translate('landing-page.background')})`,
          ]}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          px={['24px', '8%']}
          py={['24px', '5%']}
        >
          <Common.Box
            display="flex"
            alignItems="center"
            position="relative"
            overflow="hidden"
            zIndex="2"
            pl={['20px', '75px']}
            pt="60px"
            pb={['0px', '40px']}
            width="100%"
            height="100%"
            minHeight="300px"
            borderRadius={['6px', '24px']}
            backgroundImage={[
              `linear-gradient(180deg, rgba(0, 3, 82, 0.00) 0%, rgba(98, 177, 250, 0.10) 52.5%, #000352 100.01%), linear-gradient(0deg, rgba(98, 177, 250, 0.20) 0%, rgba(98, 177, 250, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${i18n.translate(
                'landing-page.banner-image'
              )})`,
              `linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), linear-gradient(90deg, rgba(0, 3, 82, 0.80) 0%, rgba(0, 3, 82, 0.16) 50%), url(${i18n.translate(
                'landing-page.banner-image'
              )})`,
            ]}
            backgroundSize="cover"
            backgroundPosition="center, top left"
            backgroundRepeat="no-repeat"
          >
            <Ribbon>{i18n.translate('landing-page.ribbon')}</Ribbon>
            <Common.Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              justifySelf="center"
              maxHeight="541px"
              height="100%"
            >
              <Common.Box
                fontSize={['32px', '46px']}
                fontWeight={['700', '800']}
                lineHeight="120%"
                style={{ textTransform: !mobileClient ? 'uppercase' : 'none' }}
              >
                <GoldText>
                  {i18n.translate('landing-page.text-part1', {
                    bonusPercentage: props.bonusPercentage,
                  })}
                  &nbsp;
                </GoldText>
                <Common.Box display={['flex', 'inline']}>
                  {i18n.translate('landing-page.text-part2')}
                  &nbsp;
                  <Common.Box display={['none', 'contents']}>
                    <br />
                  </Common.Box>
                  {i18n.translate('landing-page.text-part3', {
                    maxBonusAmount: props.maxBonusAmount,
                  })}
                  &nbsp;
                </Common.Box>
                <GoldText>
                  {i18n.translate('landing-page.text-part4', {
                    maxFixedFreespins: props.maxFixedFreespins,
                  })}
                </GoldText>
              </Common.Box>
              <Common.Box
                fontSize={['16px', '24px']}
                fontWeight={['700', '600']}
                lineHeight="120%"
                pt={['24px', 'unset']}
              >
                <HtmlContent
                  html={{
                    __html: i18n.translate(`landing-page.banner-text`),
                  }}
                />
              </Common.Box>
              <Common.Box display={['none', 'contents']}>
                <LandingPageBottomPart registerQuery={registerQuery} />
              </Common.Box>
            </Common.Box>
          </Common.Box>
          <Common.Box display={['contents', 'none']}>
            <LandingPageBottomPart registerQuery={registerQuery} mobileClient />
          </Common.Box>
        </Common.Box>
        {paymentProviders?.length > 0 ? (
          <Common.Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            background="white"
            py="20px"
          >
            <PaymentProviderImages providers={paymentProviders} />
          </Common.Box>
        ) : null}
      </Common.Box>
    </LandingPageLayout>
  )
}

LandingPage.propTypes = {
  bonusPercentage: PropTypes.number.isRequired,
  maxBonusAmount: PropTypes.number.isRequired,
  maxFixedFreespins: PropTypes.number.isRequired,
}

// for @loadable/components
export default LandingPage
