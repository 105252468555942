import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as Constants from '../constants'
import * as Cookies from '../cookies-module'
import * as Configuration from '../configuration'

import LandingPageOne from './lading-page-default-1'
import LandingPageThree from './lading-page-default-3'
import LandingPageTwo from './lading-page-default-2'

function getLandingPageComponent(props, number) {
  const LandingPageMapping = {
    '0': <LandingPageOne {...props} />,
    '1': <LandingPageTwo {...props} />,
    '2': <LandingPageThree {...props} />,
  }
  return LandingPageMapping[number]
}

function RenderLandingPage(props) {
  switch (props.pathname) {
    case '/':
      return props.isRandomLpEnabled ? (
        getLandingPageComponent[(props, props.currentLandingPage - 1)]
      ) : (
        <LandingPageOne {...props} />
      )
    case '/landing-pages/1':
      return getLandingPageComponent[(props, 0)]
    case '/landing-pages/2':
      return getLandingPageComponent[(props, 1)]
    case '/landing-pages/3':
      return getLandingPageComponent[(props, 2)]
    default:
      return <LandingPageOne {...props} />
  }
}

RenderLandingPage.propTypes = {
  pathname: PropTypes.string.isRequired,
  isRandomLpEnabled: PropTypes.bool.isRequired,
  currentLandingPage: PropTypes.string.isRequired,
}

export function LandingPage(props) {
  const location = ReactRouter.useLocation()
  const [landingPage, setLandingPage] = Cookies.useCookie(
    Constants.CookieKeys.LANDING_PAGE
  )
  const isRandomLpEnabled = ReactRedux.useSelector(state =>
    Configuration.isRandomLpEnabled(state)
  )
  const configuration = ReactRedux.useSelector(state => state.configuration)
  const currentLandingPage = React.useMemo(
    () => landingPage || configuration?.landingPage,
    [landingPage, configuration]
  )

  React.useEffect(() => {
    if (isRandomLpEnabled && !landingPage && configuration?.landingPage) {
      setLandingPage(configuration?.landingPage, {
        httpOnly: false,
        maxAge: 10 * 365 * 24 * 60 * 60, // 10 years
        path: '/',
      })
    }
  }, [isRandomLpEnabled, landingPage, configuration])

  return (
    <RenderLandingPage
      currentLandingPage={currentLandingPage}
      isRandomLpEnabled={isRandomLpEnabled}
      pathname={location.pathname}
      {...props}
    />
  )
}

// for @loadable/components
export default LandingPage
